import { useEffect } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import useRestaurants from "../../models/restaurants/useRestaurants";
import Landing from ".";
import USForm from "./Form";
import AuthRoute from "../../containers/AuthRoute";
import USQR from "./QR";
import USThanks from "./Thanks";
import USError from "./Error";
import useAuth from "../../models/auth/useAuth";
import USAlreadyParticipating from "./AlreadyParticipating";

const UploadStoryRouter = () => {
  const { restaurantId } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { restaurant, fetchRestaurant, clearRestaurant, canUserParticipate } =
    useRestaurants();

  useEffect(() => {
    fetchRestaurant(restaurantId);
    return () => {
      clearRestaurant();
    };
  }, [restaurantId]);

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/form" element={<USForm />} />
      <Route path="/story-qr/:storyId" element={<USQR />} />
      <Route path="/thanks" element={<USThanks />} />
      <Route path="/error" element={<USError />} />
      <Route
        path="/already-participating"
        element={<USAlreadyParticipating />}
      />
    </Routes>
  );
};

export default UploadStoryRouter;
