import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@mui/material";
import useAuth from "../../../../models/auth/useAuth";
import { toast } from "react-toastify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./styles.scss";

const IgModalAccount = ({ handleCreateStory, onClose, slug }) => {
  const navigate = useNavigate();
  const { setIgAccount } = useAuth();
  const { me } = useAuth();
  const [account, setAccount] = useState();

  const submit = async () => {
    handleCreateStory(account.replace("@", "").toLowerCase());
    onClose();
  };

  return (
    <Dialog open={true} className="IgModalAccount">
      <DialogContent>
        <DialogContentText>
          Vamos a comprobar que la story se ha subido correctamente.
          <br />
          <br />
          ¿Cuál es tu nombre de usuario en Instagram?
        </DialogContentText>
        <TextField
          placeholder="Nombre en Instagram"
          autoFocus={true}
          onChange={(e) => setAccount(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <button disabled={!account} onClick={submit}>
          Enviar
        </button>
      </DialogActions>
    </Dialog>
  );
};
export default IgModalAccount;
