import { Link } from "react-router-dom";
import useRestaurants from "../../models/restaurants/useRestaurants";
import handIcon from "../../assets/images/hand1.png";
import { useEffect } from "react";
import { gaEvent } from "../../utils/gaHelper";

import "./styles.scss";

const USLanding = () => {
  const { restaurant } = useRestaurants();

  useEffect(() => {
    gaEvent({ name: "us_landing" });
  }, []);

  return (
    restaurant.id && (
      <div className="USLanding">
        <div className="USLanding__main">
          <div className="USLanding__image">
            <img src={handIcon} />
          </div>
          <div className="USLanding__title title">
            ¿Quieres {restaurant.influencer_prize} gratis?
          </div>
          <div className="USLanding__text">
            ¡Es muy fácil! Solo tienes que subir una story a Instagram, a
            continuación te indicamos los pasos a seguir.
          </div>
        </div>
        <div className="USLanding__button">
          <Link to="form">
            <button className="button">¡Vamos!</button>
          </Link>
          <div className="USLanding__terms">
            Continuando, aceptas los{" "}
            <a href="https://www.wubalu.com/terms" target="_blank">
              términos y condiciones
            </a>
          </div>
        </div>
      </div>
    )
  );
};

export default USLanding;
