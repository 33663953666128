import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import QRCode from "react-qr-code";
import useAuth from "../../../models/auth/useAuth";
import useStories from "../../../models/stories/useStories";
import { gaEvent } from "../../../utils/gaHelper";

import "./styles.scss";

const USQR = () => {
  const { storyId } = useParams();
  const navigate = useNavigate();
  const { fetchStory, fetchStoryStatus, story } = useStories();
  const { user } = useAuth();

  useEffect(() => {
    if (story) {
      fetchStoryStatus(storyId);
    }
  }, [story]);

  useEffect(() => {
    fetchStory(storyId);
  }, [storyId]);

  useEffect(() => {
    gaEvent({ name: "us_show_qr" });
  }, []);

  if (story) {
    story.status === "VALIDATED" && navigate("../thanks");
    story.status === "REJECTED" && navigate("../error");
  }

  return (
    story && (
      <div className="USQR">
        <div className="USQR__main">
          <div className="title">¡Gracias!</div>
          {/* <div className="USQR__text">
            Gracias por subir la story con la cuenta de Instagram{" "}
            <b>
              <u>@{user.ig_account}</u>
            </b>
            <br />
          </div> */}
          <div className="USQR__text">
            <b>Muestra este código QR al camarerx</b> para recibir tu premio.
          </div>
          <div className="USQR__qr-wrapper">
            <div className="USQR__qr">
              <QRCode
                size={256}
                style={{
                  height: "auto",
                  maxWidth: "300px",
                  width: "100%",
                }}
                value={JSON.stringify(story)}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
        </div>
        <div>
          <Link to="../form">
            <button className="button secondary">Rechazar y volver</button>
          </Link>
        </div>
      </div>
    )
  );
};

export default USQR;
